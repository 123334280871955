import React, { useEffect, useState } from 'react';
import '../profile/ProfilePage.css';
import UnderHeader from '../../components/UnderHeader';

const AdminPanelPage = () => {
  const [user, setUser] = useState(null);
  const [purchases, setPurchases] = useState([]);
  const [activeSection, setActiveSection] = useState('profile');

  useEffect(() => {
    fetch('/api/user', { credentials: 'include' })
      .then(res => res.json())
      .then(userData => {
        if (userData && userData.id && userData.role == 'admin') {
          setUser(userData);
          return fetch(`/orders/readAll`, { credentials: 'include' });
        } else {
          setUser(null);
          throw new Error('User not authenticated');
        }
      })
      .then(res => res.json())
      .then(data => {
        if (data.orders) {
          setPurchases(data.orders);
        }
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
        setUser(null);
      });
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('Номер заказа скопирован в буфер обмена.');
      })
      .catch(err => {
        console.error('Ошибка при копировании:', err);
        alert('Не удалось скопировать номер заказа.');
      });
  };

  return (
    <div className="profile-page">
      <UnderHeader title="User Profile"></UnderHeader>
      <div className="profile-navigation">
        <button
          className={activeSection === 'profile' ? 'active' : ''}
          onClick={() => setActiveSection('profile')}
        >
          Профиль
        </button>
        <button
          className={activeSection === 'orders' ? 'active' : ''}
          onClick={() => setActiveSection('orders')}
        >
          Покупки
        </button>
      </div>
      <div className="user-purchase-content">
        {user ? (
          activeSection === 'profile' ? (
            <div className="profile-info">
              <p><strong>Name:</strong> {user.name}</p>
              <p><strong>Email:</strong> {user.email}</p>
              <p><strong>Your bufferka coins:</strong> {user.coinsBalance}</p>
              <div className="telegram-section">
                <p><strong>TG nickname:</strong></p>
              </div>
                <p><strong>Telegram ID:</strong> {user.telegramId}</p>
            </div>
          ) : (
            <div className="user-purchases">
              <h2>Покупки</h2>
              {purchases.length > 0 ? (
                <div className="purchases-container">
                  {purchases.map(purchase => (
                    <div key={purchase.id} className="purchase-card">
                      <div className="purchase-header">
                        <p><strong>Order ID:</strong> {purchase.id}</p>
                        <p><strong>User email:</strong> {purchase.id}</p>
                        <button className="copy-id-button" onClick={() => copyToClipboard(purchase.id)}></button>
                      </div>
                      <div className="purchase-content">
                        <p><strong>Товары:</strong></p>
                        <ul>
                          {purchase.items.map((item, index) => (
                            <li key={index}>
                              <strong>{item.name}</strong>
                              (x{item.quantity}) - {item.status}
                            </li>
                          ))}
                        </ul>
                        {purchase.coupon && (
                          <p>
                            <strong>Применен купон:</strong> {purchase.coupon.code} (-{purchase.coupon.discountValue}%)
                          </p>
                        )}
                        <p><strong>Всего:</strong> {purchase.total} {purchase.currency}</p>
                        <p><strong>Состояние покупки:</strong> {purchase.status}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No purchases yet.</p>
              )}
            </div>
          )
        ) : (
          <p>Please log in as admin to view this profile.</p>
        )}
      </div>
    </div>
  );
};

export default AdminPanelPage;